import { Inject, Injectable } from '@angular/core';
import { ITU_CODE } from '@adlatus-gui/domain/clearing-custom';

@Injectable({
  providedIn: 'root',
})
export class TicketOriginatorUtil {

  constructor(@Inject(ITU_CODE) private ituCode: string) {}

  isOriginatorPlatform(originator: string) {
    return this.ituCode === originator;
  }
}

