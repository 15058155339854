<lib-main-layout>
  <ng-template #navigationTemplate>
    <ul class='list-none p-3 m-0'>
      <li>
        <div pRipple pStyleClass='@next' enterFromClass='hidden' enterActiveClass='slidedown' leaveToClass='hidden'
             leaveActiveClass='slideup'
             class='p-ripple p-element p-3 flex align-items-center justify-content-between text-600 cursor-pointer'>
          <span class='font-medium'>MAIN</span><i class='pi pi-chevron-down'></i><span class='p-ink'></span>
        </div>
        <ul class='list-none p-0 m-0 overflow-hidden'>
          <li>
            <a pRipple routerLink=''
               class='p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors'><i
              class='pi pi-home mr-2'></i><span class='font-medium'>Dashboard</span><span class='p-ink'></span></a>
          </li>


          <li><a pRipple pStyleClass='@next' enterFromClass='hidden' enterActiveClass='slidedown'
                 leaveToClass='hidden' leaveActiveClass='slideup'
                 class='p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors'><i
            class='pi pi-ticket mr-2'></i><span class='font-medium'>Issues</span><i
            class='pi pi-chevron-down ml-auto'></i><span class='p-ink'
                                                         style='height: 247px; width: 247px; top: -94.5px; left: 5.5px;'></span></a>
            <ul
              class='list-none py-0 pl-3 pr-0 m-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out hidden'>
              <li><a pRipple routerLink='tickets/inbox'
                     class='p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors'><i
                class='pi pi-inbox mr-2'></i><span class='font-medium'>Inbox</span><span
                class='inline-flex align-items-center justify-content-center ml-auto bg-blue-500 text-0 border-circle'
                style='min-width: 1.5rem; height: 1.5rem;'>{{ totalInbox }}</span><span class='p-ink'></span></a>
              </li>
              <li><a pRipple routerLink='tickets/outbox'
                     class='p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors'><i
                class='pi pi-send mr-2'></i><span class='font-medium'>Outbox</span><span
                class='inline-flex align-items-center justify-content-center ml-auto bg-blue-500 text-0 border-circle'
                style='min-width: 1.5rem; height: 1.5rem;'>{{ totalOutbox }}</span><span class='p-ink'></span></a>
              </li>
              <li><a pRipple routerLink='tickets/myIssues'
                     class='p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors'><i
                class='pi pi-list-check mr-2'></i><span class='font-medium'>My Issues</span><span
                class='inline-flex align-items-center justify-content-center ml-auto bg-blue-500 text-0 border-circle'
                style='min-width: 1.5rem; height: 1.5rem;'>{{ totalMyIssues }}</span><span class='p-ink'></span></a>
              </li>
              <li><a pRipple routerLink='tickets/watchingIssues'
                     class='p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors'><i
                class='pi pi-eye mr-2'></i><span class='font-medium'>Watching Issues</span><span
                class='inline-flex align-items-center justify-content-center ml-auto bg-blue-500 text-0 border-circle'
                style='min-width: 1.5rem; height: 1.5rem;'>{{ totalWatchingIssues }}</span><span
                class='p-ink'></span></a>
            </ul>
          </li>


          <li><a pRipple [routerLink]='createTicketRoute'
                 class='p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors'><i
            class='pi pi-plus-circle mr-2'></i><span class='font-medium'>Create an Issue</span><span
            class='p-ink'></span></a>
          </li>
        </ul>
      </li>
    </ul>
    <ul class='list-none p-3 m-0'>
      <li>
        <div pRipple pStyleClass='@next' enterFromClass='hidden' enterActiveClass='slidedown' leaveToClass='hidden'
             leaveActiveClass='slideup'
             class='p-ripple p-element p-3 flex align-items-center justify-content-between text-600 cursor-pointer'>
          <span class='font-medium'>APPLICATION</span><i class='pi pi-chevron-down'></i><span class='p-ink'></span>
        </div>
        <ul class='list-none p-0 m-0 overflow-hidden'>

          <li><a pRipple pStyleClass='@next' enterFromClass='hidden' enterActiveClass='slidedown'
                 leaveToClass='hidden' leaveActiveClass='slideup'
                 class='p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors'><i
            class='pi pi-cog mr-2'></i><span class='font-medium'>Settings</span><i
            class='pi pi-chevron-down ml-auto'></i><span class='p-ink'
                                                         style='height: 247px; width: 247px; top: -94.5px; left: 5.5px;'></span></a>
            <ul
              class='list-none py-0 pl-3 pr-0 m-0 overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out hidden'>
              <li><a pRipple [href]='partnershipMgmtUrl' target='_blank'
                     class='p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors'><i
                class='pi pi-users mr-2'></i><span class='font-medium'>Partnership Mgmt.</span><span
                class='p-ink'></span></a>
              </li>
              <li><a pRipple routerLink='settings/routing/inbox'
                     class='p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors'><i
                class='pi pi-directions mr-2'></i><span class='font-medium'>Inbox Routing Config</span><span
                class='p-ink'></span></a>
              </li>
              <li><a pRipple routerLink='settings/routing/outbox'
                     class='p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors'><i
                class='pi pi-directions mr-2'></i><span class='font-medium'>Outbox Routing Config</span><span
                class='p-ink'></span></a>
              </li>
              <li><a pRipple routerLink='settings/sla'
                     class='p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors'><i
                class='pi pi-sliders-v mr-2'></i><span class='font-medium'>SLA Config</span><span
                class='p-ink'></span></a>
              </li>
            </ul>
          </li>

          <li><a pRipple routerLink='help'
                 class='p-ripple p-element flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors'><i
            class='pi pi-question-circle mr-2'></i><span class='font-medium'>Help</span><span class='p-ink'></span></a>
          </li>
        </ul>
      </li>
    </ul>
  </ng-template>
  <ng-template #userTemplate>
    <li (click)='notificationsPanel.toggle($event)'>
      <a pRipple
         class='p-ripple p-element flex lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer
           transition-duration-150 transition-colors'>
        <i class='p-element pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0'
           [class.p-overlay-badge]='notificationsMenuItems.length > 0'>
          <span *ngIf='notificationsMenuItems.length > 0' class='p-badge p-component p-badge-dot p-badge-danger'></span>
        </i>
        <p-overlayPanel [style]="{ width: '20vw', maxHeight: '30vh', overflowY: 'auto' }" #notificationsPanel>
          <div class='notifications-container'>
            <ng-container
              *ngTemplateOutlet='notificationsMenuItems.length ? notificationsContent : noNotifications'></ng-container>
          </div>
          <ng-template #notificationsContent>
            <p-menu class='notifications-menu' [style]="{ width: '100%', paddingTop: '0px', paddingBottom: '40px'}"
                    [model]='notificationsMenuItems'>
            </p-menu>
            <div (click)='this.markAllAsRead()' class='mark-all-read'>
              <label class='mark-all-read-label'>Mark all as read</label>
            </div>
          </ng-template>
          <ng-template #noNotifications>
            <div class='m-4'>
              <label class='font-medium text-900'>There are no notifications.</label>
            </div>
          </ng-template>
        </p-overlayPanel>
      </a>
    </li>
  </ng-template>
</lib-main-layout>
