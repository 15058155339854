import { MessageService } from 'primeng/api';
import { provideRouter } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { apiErrorInterceptor } from '@adlatus-gui/shared';
import { BASE_PATH as CLEARING_API_URI } from '@adlatus-gui/domain/clearing';
import { authInterceptor, provideAuthentication } from '@adlatus-gui/domain/authentication';
import {
  BASE_PATH as CLEARING_CUSTOM_API_URI,
  ITU_CODE,
  LIGHT_PARTNER_API_PATH
} from '@adlatus-gui/domain/clearing-custom';
import { appRoutes } from './app.routes';
import { environment } from '../environments/environment';
import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideAnimations(),
    provideHttpClient(withInterceptors([
      authInterceptor,
      apiErrorInterceptor
    ])),
    provideRouter(appRoutes),
    MessageService,
    ...provideAuthentication(environment.KEYCLOAK),
    { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { timezone: 'Europe/Berlin' } },
    { provide: ITU_CODE, useValue: environment.ITU_CODE },
    { provide: CLEARING_API_URI, useValue: environment.CLEARING_API_ENDPOINT },
    { provide: CLEARING_CUSTOM_API_URI, useValue: environment.CLEARING_CUSTOM_API_ENDPOINT },
    { provide: LIGHT_PARTNER_API_PATH, useValue: environment.LIGHT_PARTNER_API_PATH }
  ]
};
