import { Injectable } from '@angular/core';
import { Client } from '@stomp/stompjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class WebSocketService {
  private client!: Client;

  connect(onConnect: (client: Client) => void) {
    this.client = new Client({
      brokerURL: environment.MESSAGE_BROKER_URL,
      onConnect: () => {
        onConnect(this.client);
      },
      debug: (msg) => console.log(msg),
      onWebSocketError: (err) => {
        console.log(err);
      },
      onStompError: (err) => {
        console.log(err);
      }
    });

    this.client.activate();
  }

  disconnect() {
    if (this.client && this.client.connected) {
      this.client.forceDisconnect();
    }
  }
}
