import { Route } from '@angular/router';
import {
  adminGuard,
  authGuard,
  lightGuard,
  normalGuard,
  operatorGuard
} from '@adlatus-gui/domain/authentication';


export const appRoutes: Route[] = [
  {
    path: '',
    title: 'Dashboard',
    canActivate: [normalGuard],
    loadComponent: () => import('./pages/main-page')
      .then(m => m.MainPageComponent)
  },
  {
    path: 'tickets/:type',
    title: 'Issues',
    canActivate: [normalGuard],
    loadComponent: () => import('./pages/tickets-page')
      .then(m => m.TicketsPageComponent)
  },
  {
    path: 'tickets/:type/:id',
    title: 'Single Issue',
    canActivate: [normalGuard],
    loadComponent: () => import('./pages/single-ticket-page')
      .then(m => m.SingleTicketPageComponent)
  },
  {
    path: 'clearing-data/:id',
    title: 'Add Ticket Clearing Data',
    canActivate: [normalGuard],
    loadComponent: () => import('./pages/add-ticket-data-page')
      .then(m => m.AddTicketDataPageComponent)
  },
  {
    path: 'create-ticket',
    title: 'Create a Issue',
    canActivate: [operatorGuard],
    loadComponent: () => import('./pages/create-ticket-page')
      .then(m => m.CreateTicketPageComponent)
  },
  {
    path: 'settings/routing/:type',
    title: 'List Routing Configuration',
    canActivate: [adminGuard],
    loadComponent: () => import('./pages/routing-config-page')
      .then(m => m.RoutingConfigPageComponent)
  },
  {
    path: 'settings/routing/:type/:id',
    title: 'Single Routing Config',
    data: {
      backTo: 'settings/routing'
    },
    canActivate: [adminGuard],
    loadComponent: () => import('./pages/single-routing-config-page')
      .then(m => m.SingleRoutingConfigPageComponent)
  },
  {
    path: 'settings/sla',
    title: 'List SLA Configuration',
    canActivate: [adminGuard],
    loadComponent: () => import('./pages/sla-config-page')
      .then(m => m.SlaConfigPageComponent)
  },
  {
    path: 'help',
    title: 'Help',
    canActivate: [normalGuard],
    loadComponent: () => import('./pages/help-page')
      .then(m => m.HelpPageComponent)
  },
  {
    path: 'light/tickets/:id',
    title: 'Single Issue',
    canActivate: [lightGuard],
    loadComponent: () => import('./pages/single-ticket-page')
      .then(m => m.SingleTicketPageComponent)
  }
  ,
  {
    path: 'light/clearing-data/:id',
    title: 'Add Ticket Clearing Data',
    canActivate: [lightGuard],
    loadComponent: () => import('./pages/add-ticket-data-page')
      .then(m => m.AddTicketDataPageComponent)
  },
  {
    path: 'light/create-ticket',
    title: 'Create a Issue',
    canActivate: [lightGuard],
    loadComponent: () => import('./pages/create-ticket-page')
      .then(m => m.CreateTicketPageComponent)
  },
  {
    path: '**',
    pathMatch: 'full',
    title: 'Page Not Found',
    loadComponent: () => import('@adlatus-gui/shared')
      .then(m => m.NotFoundPageComponent)
  }
].map(
  route => ({
    ...route,
    canActivate: route.canActivate ? [...route.canActivate, authGuard] : [authGuard]
  })
) as Route[];
