import { filter } from 'rxjs';
import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Ripple } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { MainLayoutComponent, TimeAgoPipe } from '@adlatus-gui/shared';
import { ClearingTicket, ClearingTicketCustomService, ClearingTicketService } from '@adlatus-gui/domain/clearing';
import { ITU_CODE, LightPartnerService, Notification } from '@adlatus-gui/domain/clearing-custom';
import { ClearingTicketUtils } from './core/util/clearingTicket.util';
import { environment } from '../environments/environment';
import { MenuItem } from 'primeng/api';
import { TicketOriginatorUtil } from './core/util/ticketOriginatorUtil';
import { TicketTypeEnum } from './core/constant/ticketTypeEnum';
import { BadgeModule } from 'primeng/badge';
import { MenuModule } from 'primeng/menu';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { WebSocketService } from './core/service/WebSocketService';
import { AuthenticationService } from '@adlatus-gui/domain/authentication';

@Component({
  standalone: true,
  imports: [
    MainLayoutComponent,
    Ripple,
    RouterLink,
    BadgeModule,
    MenuModule,
    OverlayPanelModule,
    StyleClassModule,
    NgIf,
    NgTemplateOutlet
  ],
  providers: [
    LightPartnerService,
    ClearingTicketUtils,
    WebSocketService,
    ClearingTicketCustomService,
    ClearingTicketService
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnDestroy {
  @ViewChild('notificationsPanel') notificationsPanel!: OverlayPanel;

  title = 'clearing-app';
  totalInbox: number | null = 0;
  totalOutbox: number | null = 0;
  totalMyIssues = '0';
  partnershipMgmtUrl: string = environment.PARTNERSHIP_APP_URL;
  totalWatchingIssues = '0';
  notificationsMenuItems: MenuItem[] = [];

  constructor(
    @Inject(ITU_CODE) ituCode: string,
    private router: Router,
    private lightPartnerService: LightPartnerService,
    private authenticationService: AuthenticationService,
    private webSocketService: WebSocketService,
    private clearingTicketUtils: ClearingTicketUtils,
    private ticketOriginatorUtil: TicketOriginatorUtil,
    private clearingTicketCustomService: ClearingTicketCustomService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const url = this.router.url;
        if (!url.toLowerCase().includes('light')) {
          this.clearingTicketUtils
            .outboxClearingTicketsCount(ituCode)
            .subscribe((response) => {
              this.totalOutbox = response.body;
              this.clearingTicketUtils.updateOutboxCount(response.body!);
            });

          this.clearingTicketUtils
            .inboxClearingTicketsCount(ituCode)
            .subscribe((response) => {
              this.totalInbox = response.body;
              this.clearingTicketUtils.updateInboxCount(response.body!);
            });


          this.clearingTicketUtils.myTickets().subscribe((response) => {
            const count = response.length.toString();
            this.totalMyIssues = count;
            this.clearingTicketUtils.updateMyIssuesCount(count);
          });
          this.clearingTicketUtils.myWatchingTickets().subscribe((response) => {
            const count = response.length.toString();
            this.totalWatchingIssues = count;
            this.clearingTicketUtils.updateWatchingIssues(count);
          });

          this.clearingTicketUtils.totalInbox$.subscribe(
            (count) => (this.totalInbox = count)
          );
          this.clearingTicketUtils.totalOutbox$.subscribe(
            (count) => (this.totalOutbox = count)
          );
          this.clearingTicketUtils.totalMyIssues$.subscribe(
            (count) => (this.totalMyIssues = count)
          );
          this.clearingTicketUtils.totalWatchingIssues$.subscribe(
            (count) => (this.totalWatchingIssues = count)
          );
        }
      });

    if (this.authenticationService.isLoggedIn()) {
      this.webSocketService.connect((client) => {
        client.subscribe(`/topic/${this.authenticationService.username}/notifications`, message => {
          const notification: Notification = JSON.parse(message.body);
          const notifyMenuItem = this.buildNotification(notification);
          this.notificationsMenuItems.push(notifyMenuItem);
        });
        Notification;
      });
    }

    this.clearingTicketCustomService.listMyNotifications().subscribe({
      next: (notifications) =>
        this.handleListMyNotificationsResponse(notifications)
    });
  }

  private readonly _timeAgoPipe = new TimeAgoPipe();

  private handleListMyNotificationsResponse(notifications: Notification[]) {
    if (notifications && notifications.length) {
      this.notificationsMenuItems = notifications.map(
        (notification: Notification) => {
          return this.buildNotification(notification);
        }
      );
    }
  }

  private buildNotification(notification: Notification) {
    return {
      label: `<div class='m-1' style='position: relative;'><b>Clearing Ticket ${
        notification.clearingTicket.id
      }</b><p> ${
        notification.text
      }</p><small>${this._timeAgoPipe.transform(
        notification.createdAt
      )}</small></div><div style='margin-top: 20px; width: 18vw; border-bottom: 0.3px solid rgba(0, 0, 0, 0.1); position: absolute; bottom: 0; left: 50%; transform: translateX(-50%);'></div>`,
      style: { width: '100%' },
      command: () =>
        this.handleNotificationClick(notification.clearingTicket),
      escape: false,
      data: notification.clearingTicket.id
    };
  }

  private handleNotificationClick(clearingTicket: ClearingTicket) {
    if (clearingTicket && clearingTicket.id) {
      const ticketType = this.ticketOriginatorUtil.isOriginatorPlatform(
        clearingTicket.originator
      )
        ? TicketTypeEnum.OUTBOX
        : TicketTypeEnum.INBOX;
      this.clearingTicketCustomService
        .readMyNotificationsByTicketId(clearingTicket.id)
        .subscribe({
          next: () => {
            this.notificationsPanel.hide();
            this.notificationsMenuItems = this.notificationsMenuItems.filter(
              (item) => item['data'] !== clearingTicket.id
            );
          }
        });
      this.router.navigate(['tickets', ticketType, clearingTicket.id]);
    }
  }

  get createTicketRoute() {
    return this.lightPartnerService.getIsLightPartner()
      ? ['light', 'create-ticket']
      : ['create-ticket'];
  }

  markAllAsRead() {
    this.notificationsPanel.hide();
    this.notificationsMenuItems = [];
    this.clearingTicketCustomService.markAllMyNotificationsAsRead()
      .subscribe();
  }

  ngOnDestroy(): void {
    this.webSocketService.disconnect();
  }
}
